import React, { useEffect, useState } from 'react';
import Paginator from '../paginator';
import { useDispatch, useSelector } from 'react-redux';
import { apiurl, wsurl } from '../../utils/constants';


function ListInve() {

	const [inve, setInve] = useState([]);
	const [group, setGroup] = useState([]);
	const [store, setStore] = useState({});
	const { role, mail, comp } = useSelector((state) => state.user);
	const [verTodasEstado, setVerTodasEstado] = useState({});
	
	const [filterCode, setFilterCode] = useState('');
	const [filterDesc, setFilterDesc] = useState('');
	const [filterGroup, setFilterGroup] = useState('');

	
	useEffect(() => {
		loadInve();
		listItemInStor();
	}, []);
  
	const handleVerTodasClick = (code) => {
		setVerTodasEstado(prevState => ({
			...prevState,
			[code]: true,
		}));
	};

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [order, setOrder] = useState('asc'); // Puede ser 'asc' o 'desc'
	const [orderBy, setOrderBy] = useState('name'); // Puede ser 'name' o 'role'
	const [sortedAndFiltered, setSortedAndFiltered] = useState([]);
	const [paginated, setPaginated] = useState([]);
	const [totalFiltered, setTotalFiltered] = useState(0);

	
	const loadInve = async () => {
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ 
					user:mail, 
					query: `SELECT 
								item.code, 
								item.name, 
								grou.grou, 
								item.hand, 
								item.orde, 
								item.comm, 
								COALESCE(SUM(req.amou), 0) AS soli
							FROM 
								inv__item item
							LEFT JOIN inv__stor stor ON stor.item = item.code
							LEFT JOIN inv__grou grou ON item.grou = grou.numb
							LEFT JOIN req__item req ON req.item = item.code AND req.take = TRUE
							GROUP BY 
								item.code, 
								item.name, 
								grou.grou, 
								item.hand, 
								item.orde, 
								item.comm
							ORDER BY 
								item.name ASC;` })
			});
			
			if (response.ok) {
				const data = await response.json();
				setInve(data);
				const GV = [...new Set(data.map(entry => entry.grou))];
				setGroup(GV);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	const listItemInStor = async () => {
		try {
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify({ user:mail, query: `SELECT ware,item,hand FROM inv__stor ORDER BY hand DESC` }),
			});
	
			if (response.ok) {
				const data = await response.json();

                const newStore = {};
				data.forEach((row) => {
                    const { ware, item, hand } = row;
                    if (!newStore[item]) {
                        newStore[item] = {};
                    }
                    newStore[item][ware] = hand;
                });
				setStore(newStore);

			} else {
				console.error('Error listWare');
			}
		} catch (error) {
			console.error('Error:', error);
			return null;
		}
	}

	const sincItem = async () => {
		const ws = new WebSocket(wsurl + '?db=' + comp);
		const sincButton = document.getElementById('sincButton');
		ws.addEventListener('open', function open() {
			console.log('connected');
			sincButton.disabled = true;
			sincButton.value = 'Sincronizando...';
		});
		ws.addEventListener('message', function(event) {
			console.log('Message from server ', event.data);
			if(event.data === 'start') {
				sincButton.value = 'Sincronización iniciada';
			}else if(event.data === 'done'){
				ws.close();
				sincButton.value = 'Sincronizado';
				setTimeout(() => {
					sincButton.value = 'Sincronizar artículos';
					sincButton.disabled = false;
				}, 3000);
				
				loadInve();
				listItemInStor();
			}else{
				sincButton.value = 'Sincronizando '+event.data+' artículos';
			}
		});
		ws.addEventListener('error', function(event) {
			console.log('Error:', event);
			sincButton.value = 'Error!';
		});
	}

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const sortComparator = (a, b) => {
		if (order === 'asc') {
			return a[orderBy].localeCompare(b[orderBy]);
		} else {
			return b[orderBy].localeCompare(a[orderBy]);
		}
	};

	useEffect(() => {
		const filtered = inve.filter(item => {
			const CodeMatches = item.code.toLowerCase().includes(filterCode.toLowerCase());
			const DescMatches = item.name.toLowerCase().includes(filterDesc.toLowerCase());
			const GroupMatches = filterGroup === '' || item?.grou?.toLowerCase().includes(filterGroup.toLowerCase());
			return CodeMatches && DescMatches && GroupMatches;
		});

		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;

		setPaginated(filtered.sort(sortComparator).slice(startIndex, endIndex));
		setSortedAndFiltered(filtered);

	}, [inve, currentPage, filterCode, filterDesc, filterGroup, order, orderBy]);

	useEffect(() => {
		setTotalFiltered(sortedAndFiltered.length);
	}, [sortedAndFiltered]);

	useEffect(() => {
		setCurrentPage(1);
	}, [filterCode,filterDesc,filterGroup])
	


	return (
		<>
		<form>
			<main>
				<h1 data-icon=''>Inventario</h1>
				<nav>
				<article>
					<table>
						<thead>
							<tr>
								<td onClick={() => handleRequestSort('code')}> Código {orderBy === 'code' && ( <orm-icon>{order === 'asc' ? '':''}</orm-icon> )} </td>
								<td onClick={() => handleRequestSort('name')}> Artículo {orderBy === 'name' && ( <orm-icon>{order === 'asc' ? '':''}</orm-icon> )} </td>
                                <td>Existencias</td>
                                <td>Almacenaje</td>
                                <td></td>
							</tr>
						</thead>
						<tbody>
						{
							paginated.map((inve) => ( 
								<tr key={inve.code} >
									<th>{inve.code}</th>
									<td className='ellipsis' style={{ maxWidth: '650px' }}>
										<b>{ inve.name }</b>
										<div>{inve.grou }</div>
									</td>
									<td className={`state ${inve.hand === 0 ? 'red' : ''}`}>
										<time>Actual: {inve.hand}</time>
										<time>Solicitado: {inve.soli ?? 0}</time>
										<time>Comprado: {inve.orde}</time>
										<time>Vendido: {inve.comm}</time>
									</td>
									<td className={`state`}>
										<div className={verTodasEstado[inve.code] ? '' : 'clip'}>
											{
											store?.[inve.code] &&
											Object.entries(store[inve.code]).map(([ware, cantidad]) => (
												<time className={cantidad < 1 ? 'disabled' : ''} key={`${inve.code}-${ware}`}>{ware}: {cantidad}</time>
											))}
										</div>
										{Object.keys(store?.[inve.code] || {}).length > 3 && !verTodasEstado[inve.code] && (
												<a href="#" onClick={() => handleVerTodasClick(inve.code)}>Ver todas</a>
											)}
									</td>
								</tr>
							))
						}
                        </tbody>
                    </table>
                </article>
                </nav>
				<nav>
						<article><Paginator totalItems={totalFiltered} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={setCurrentPage}/> </article>
				</nav>
            </main>
            <aside>
				{role === 3 && (
					<article>
						<h2>Acciones</h2>
						<fieldset>
							<input type="button" id='sincButton' value="Sincronizar artículos" onClick={sincItem} />
						</fieldset>
					</article>
				)}
				<article>
					<h2>Filtros</h2>
					<fieldset>
						<legend>Identificación</legend>
						<input type="text" placeholder="Código" value={filterCode} onChange={e => setFilterCode(e.target.value)} />
						<input type="text" placeholder="Descripción" value={filterDesc} onChange={e => setFilterDesc(e.target.value)} />
						<select value={filterGroup} onChange={e => setFilterGroup(e.target.value)}>
							<option value="">Grupo</option>
							{group.map(( grou ) => (
								<option key={grou} value={grou}>
								{grou}
								</option>
							))}
						</select>
					</fieldset>
				</article>
			</aside>
        </form>
        </>
    )
}


export default ListInve;